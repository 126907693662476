import { Customer } from "../customer/Customer";
import { User } from "../user/User";
import { Message } from "./Message";

export class Thread {
    public messages: Message[] = [];
    public companyName: string | undefined;
    public customer: Customer | undefined;
    public user: User | undefined;
    public resolved_by_user: User;
    public subject: string;
    constructor(
        public threadId: number,
        public customerId: number,
        public userId: number,
        public type: number,
        public status: number,
        public pinned: boolean,
        public notifyOpen: boolean,
        public createdDate: number,
        public resolved: number,
        public typ_id?: number,
        public subscribe?: boolean,
        public resolved_by_user_id?: number,
        public project_id?: number,
    ){
      this.setStatus();
    }

    setStatus() {
      if(this.resolved) this.status = 2;
      else this.status = 1;
    }

    getThreadTitle(len?: number): string {
      if(this.messages.length > 0){
        this.messages.sort((a,b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
        return this.cutString(this.messages[0].message, len);
      }
      return '---';
    }

    getJSON(): any {
      let messages = [];
      this.messages.forEach(msg => {
        if(msg.message != null && msg.message.length > 0 || msg.attachment_type == "image"){
          messages.push({
            message: msg.message, 
            timestamp: msg.timestamp,
            attachment_type: msg.attachment_type,
            attachment: msg?.attachment
          });
        }
      });
      return {
        thread: {
          thread_id: this.threadId,
          customer_id: this.customerId,
          project_id: this.project_id,
          user_id: this.userId,
          typ: this.type,
          status: this.status,
          pinned: this.pinned,
          notify_open: this.notifyOpen,
          created: this.createdDate,
          messages: messages,
          resolved: this.resolved,
          resolved_by_user_id: this.resolved_by_user_id,
          subject: this.subject,
        }
      }
    }

    cutHtml(htmlString: string, maxLength: number) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = htmlString;

      let truncatedText = '';
      let currentLength = 0;

      const traverseAndTruncate = (node: Node): void => {
          if (node.nodeType === Node.TEXT_NODE) {
              const textContent = node.textContent || '';
              const remainingLength = maxLength - currentLength;

              if (remainingLength >= textContent.length) {
                  truncatedText += textContent;
                  currentLength += textContent.length;
              } else {
                  truncatedText += textContent.substring(0, remainingLength);
                  return; // Exit recursion
              }
          } else if (node.nodeType === Node.ELEMENT_NODE) {
              const element = node as HTMLElement;
              const tag = element.tagName.toLowerCase();

              if (tag === 'br') {
                  // Ignore line breaks without accompanying text
                  if (truncatedText.trim() !== '') {
                      truncatedText += '<br>';
                  }
              } else {
                  truncatedText += `<${tag}`;
                  if (element.hasAttributes()) {
                      const attrs = element.attributes;
                      for (let i = 0; i < attrs.length; i++) {
                          const attr = attrs[i];
                          truncatedText += ` ${attr.name}="${attr.value}"`;
                      }
                  }
                  truncatedText += '>';
                  for (let i = 0; i < element.childNodes.length; i++) {
                      traverseAndTruncate(element.childNodes[i]);
                  }
                  truncatedText += `</${tag}>`;
              }
          }
      };

      tempElement.childNodes.forEach(traverseAndTruncate);

      if (truncatedText.length < htmlString.length) {
          truncatedText += '...';
      }

      return truncatedText;
    }

    cutString(str: string, maxLength?: number): string {
      // let tempElement = document.createElement('div');
      // tempElement.innerHTML = str;
      // let textContent = tempElement.textContent || tempElement.innerText || '';
      // if(textContent.length <= maxLength) return str;
      // return textContent.substring(0, maxLength)+'...';


      if(str){
        if(maxLength){
          if(str.length > maxLength){
            return str.slice(0,maxLength) + ' ...'
          } else {
              return str;
          }
        } else {
          return str;
        }
      } else {
          return '';
      }
    }

    getLastMessageDate(): number {
      this.messages.sort((a,b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
      if(this.messages.length > 0){
        return this.messages[this.messages.length - 1].timestamp;
      }
      return null;
    }

    getLastMessageName(): string {
      this.messages.sort((a,b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
      if(this.messages.length > 0){
        return this.messages[this.messages.length - 1].user?.firstName[0] + '. ' + this.messages[this.messages.length - 1].user?.lastName;
      }
      return '';
    }
}
