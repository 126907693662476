<div class="dialog_view">
    <div class="dialog_window">
        <button class="dialog_window_btn_close" (click)="close()"><img src="../../../../assets/negativewhite.svg"></button>
        <div>
            <div class="dialog_window_body">
                <img [src]="imageUrl" alt="Image" id="open-image" />
            </div>
        </div>
    </div>
</div>
  