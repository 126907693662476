import { User } from "../user/User";

export class Message {
    public user: User | undefined;
    public icon: string;
    constructor(
        public messageId: number,
        public threadId: number,
        public userId: number,
        public message: string,
        public timestamp: number,
        public attachment_type: string,
        public attachment?: string | null
    ){}
}
