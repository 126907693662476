import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Project, ProjectStatus } from 'src/app/buisness-object/project/Project';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { ProjectService } from 'src/app/service/project/project.service';
import { ThreadService } from 'src/app/service/thread/thread.service';

@Component({
  selector: 'app-dialog-move-ticket',
  templateUrl: './dialog-move-ticket.component.html',
  styleUrls: ['./dialog-move-ticket.component.scss']
})
export class DialogMoveTicketComponent implements OnInit {
  @Output() closeTicketMoveEmitter = new EventEmitter<any>();
  @Output() updateCloseTicketMoveEmitter = new EventEmitter<any>();
  @Input() ticket: Thread;
  @Input() tickets: Thread[] = [];
  public ticketForm: FormGroup;
  public customers: Customer[] = [];
  public projects: Project[] = [];
  public selectedCustomer: number;
  public selectedProject: number;

  constructor(
    public formbuilder: FormBuilder,
    private ticketService: ThreadService,
    private customerService: CustomerService,
    private projectService: ProjectService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.selectedCustomer = this.ticket.customerId;
    this.selectedProject = this.ticket.project_id;
    this.customerService.getCustomers().subscribe(customers => {
      this.customers = customers;
      this.customers.sort(function (a, b) {
        if (a.company_name < b.company_name) {
          return -1;
        }
        if (a.company_name > b.company_name) {
          return 1;
        }
        return 0;
      });
      this.loadProjectsForCustomer(this.selectedCustomer);
    });
    this.ticketForm = this.formbuilder.group({
      customer_id: [this.ticket.customerId, Validators.required],
      project_id: [this.ticket.project_id],
    });
    this.ticketForm.get('customer_id').valueChanges.subscribe(customerId => {
      this.loadProjectsForCustomer(customerId);
    });
  }

  loadProjectsForCustomer(customerId: number) {
    this.projectService.getProjects(customerId).subscribe(projects => {
      this.projects = projects.filter((p) => {
        return p.awork_project.status_name != 'Abgeschlossen';
      });
      this.projects.sort(function (a, b) {
        if (a.awork_project.name < b.awork_project.name) {
          return -1;
        }
        if (a.awork_project.name > b.awork_project.name) {
          return 1;
        }
        return 0;
      });
      this.ticketForm.get('project_id').setValue(null);
    });
  }

  transferTicket() {
    const customerId = this.ticketForm.get('customer_id').value;
    let projectId: number|undefined = undefined;
    if(this.ticketForm.get('project_id').value) {
      projectId = Number(this.ticketForm.get('project_id').value);
    } else {
      projectId = null;
    }

    this.ticketService.transferThread(this.ticket.threadId, customerId, projectId).subscribe(response => {
      const updatedTickets = this.tickets.map(t => {
        if (t.threadId === this.ticket.threadId) {
            if (t.project_id === projectId) {
                return { ...t, customerId: customerId };
            }
            return t;
        }
        return t;
      }).filter(t => t.project_id === projectId || t.threadId !== this.ticket.threadId);
      this.updateCloseTicketMoveEmitter.emit(updatedTickets);
      this.dialogService.showNotification({
        title: 'Ticket verschoben',
        message: 'Ticket wurde erfolgreich zu einem anderen Kunden/Projekt verschoben.',
        success: true
      });
    }, error => {
      console.error('Thread could not be transferred:', error);
    });
  }

  close() {
    this.closeTicketMoveEmitter.emit();
  }
}
