import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import '@angular/common/locales/global/de';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomerComponent } from './view/customer/customer.component';
import { CustomerSidebarComponent } from './view/customer/customer-sidebar/customer-sidebar.component';
import { LoginComponent } from './view/login/login.component';
import { ForgotPasswordComponent } from './view/login/forgot-password/forgot-password.component';
import { MenuHeaderComponent } from './view/menu-header/menu-header.component';
import { LoadingComponent } from './view/loading/loading.component';
import { LoadingScreenInterceptor } from './service/loading/LoadingInterceptor';
import { CustomerSidebarElementComponent } from './view/customer/customer-sidebar/customer-sidebar-element/customer-sidebar-element.component';
import { CustomerCreateComponent } from './view/customer/customer-create/customer-create.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogNotificationComponent } from './view/dialog/dialog-notification/dialog-notification.component';
import { DialogQueryComponent } from './view/dialog/dialog-query/dialog-query.component';
import { TicketsComponent } from './view/tickets/tickets.component';
import { TicketListElementComponent } from './view/tickets/ticket-list-element/ticket-list-element.component';
import { TicketDetailsComponent } from './view/tickets/ticket-details/ticket-details.component';
import { CustomerCreateProjectComponent } from './view/customer/customer-create-project/customer-create-project.component';
import { CustomerCreateContactComponent } from './view/customer/customer-create/customer-create-contact/customer-create-contact.component';
import { DialogContactComponent } from './view/customer/customer-create-project/dialog-contact/dialog-contact.component';
import { DialogOpenNotificationsComponent } from './view/dialog/dialog-open-notifications/dialog-open-notifications.component';
import { TicketCreateComponent } from './view/tickets/ticket-create/ticket-create.component';
import { CustomerCreateDifferentAddressComponent } from './view/customer/customer-create-project/customer-create-different-address/customer-create-different-address.component';
import { DropdownDirective } from './directive/dropdown/dropdown.directive';
import { DialogProjectAllocationComponent } from './view/dialog/dialog-project-allocation/dialog-project-allocation.component';
import { DialogFilterStatusComponent } from './view/dialog/dialog-filter-status/dialog-filter-status.component';
import { DialogFilterUserComponent } from './view/dialog/dialog-filter-user/dialog-filter-user.component';
import { SectionComponent } from './view/customer/customer-create-project/section/section.component';
import { CustomerTicketsComponent } from './view/customer/customer-tickets/customer-tickets.component';
import { CustomerTicketElementComponent } from './view/customer/customer-tickets/customer-ticket-element/customer-ticket-element.component';
import { CustomerTicketDetailsComponent } from './view/customer/customer-tickets/customer-ticket-details/customer-ticket-details.component';
import { CustomerTicketCreateComponent } from './view/customer/customer-tickets/customer-ticket-create/customer-ticket-create.component';
import { DialogRestoreComponent } from './view/dialog/dialog-restore/dialog-restore.component';
import { CustomMarkInputComponent } from './view/custom-mark-input/custom-mark-input.component';
import { SuccessComponent } from './view/tickets/success/success.component';
import { BrowserModule } from '@angular/platform-browser';
import { DialogCustomerAppointmentsComponent } from './view/dialog/dialog-customer-appointments/dialog-customer-appointments.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { DialogSearchComponent } from './view/menu-header/dialog-search/dialog-search.component';
import { SectionOverviewComponent } from './view/customer/section-overview/section-overview.component';
import { CustomerDetailsComponent } from './view/customer/customer-details/customer-details.component';
import { ProjectDetailsComponent } from './view/customer/project-details/project-details.component';
import { TicketsNewComponent } from './view/customer/tickets-new/tickets-new.component';
import { TicketsItemComponent } from './view/customer/tickets-new/tickets-item/tickets-item.component';
import { RichTextEditorComponent } from './view/rich-text-editor/rich-text-editor.component';
import { ActionMenuComponent } from './view/customer/action-menu/action-menu.component';
import { SanitizeHtmlPipe } from './pipe/sanitize-html/sanitize-html.pipe';
import { DialogFilterTicketsComponent } from './view/dialog/dialog-filter-tickets/dialog-filter-tickets.component';
import { DialogMoveTicketComponent } from './view/dialog/dialog-move-ticket/dialog-move-ticket.component';
import { DialogEditTicketComponent } from './view/dialog/dialog-edit-ticket/dialog-edit-ticket.component';
import { DialogImageOpenComponent } from './view/dialog/dialog-image-open/dialog-image-open.component';

@NgModule({
  declarations: [
    AppComponent,
    CustomerComponent,
    CustomerSidebarComponent,
    LoginComponent,
    ForgotPasswordComponent,
    MenuHeaderComponent,
    LoadingComponent,
    CustomerSidebarElementComponent,
    CustomerCreateComponent,
    DialogNotificationComponent,
    DialogQueryComponent,
    TicketsComponent,
    TicketListElementComponent,
    TicketDetailsComponent,
    CustomerCreateProjectComponent,
    CustomerCreateContactComponent,
    DialogContactComponent,
    DialogOpenNotificationsComponent,
    TicketCreateComponent,
    CustomerCreateDifferentAddressComponent,
    DropdownDirective,
    DialogProjectAllocationComponent,
    DialogFilterStatusComponent,
    DialogFilterUserComponent,
    SectionComponent,
    CustomerTicketsComponent,
    CustomerTicketElementComponent,
    CustomerTicketDetailsComponent,
    CustomerTicketCreateComponent,
    DialogRestoreComponent,
    CustomMarkInputComponent,
    SuccessComponent,
    DialogCustomerAppointmentsComponent,
    DialogSearchComponent,
    SectionOverviewComponent,
    CustomerDetailsComponent,
    ProjectDetailsComponent,
    TicketsNewComponent,
    TicketsItemComponent,
    RichTextEditorComponent,
    ActionMenuComponent,
    SanitizeHtmlPipe,
    DialogFilterTicketsComponent,
    DialogMoveTicketComponent,
    DialogEditTicketComponent,
    DialogImageOpenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de-at'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
