<a (click)="onTicketSelected()" [attr.id]="'ticket-item_'+ticket.threadId" class="ticket-item"
  [class.ticket-item-active]="active">
  <div class="ticket-item-image-wrapper">
    <img class="ticket-item-user-img" [src]="ticket.user.getAvatar()"/>
    <div *ngIf="ticket.notifyOpen" class="notify-dot"></div>
  </div>
  <div class="ticket-item-body">
    <div class="ticket-item-body-header">
      <div class="ticket-item-body-header-column-1">
        <h2 *ngIf="ticket?.subject != null || ticket.subject?.length > 0">#{{ticket.threadId}} <span>{{ticket.subject}}</span></h2>
        <h2 *ngIf="ticket?.subject == null || ticket.subject?.length == 0" class="ticket-item-lbl-content" [innerHTML]="ticket.getThreadTitle() | sanitizeHtml"></h2>
        <label>Erstellt von {{ticket.user.getFullName()}}, {{ticket.createdDate | date: 'dd. MMM YYYY, HH:mm'}}</label>
      </div>
    </div>
    <!-- <label class="ticket-item-lbl-content" [innerHTML]="ticket.getThreadTitle() | sanitizeHtml"></label> -->
    <div class="ticket-item-body-footer">
      <label>{{ticket.messages.length > 0 ? ticket.messages.length - 1 : '0'}} Antwort(en)</label>
      <!-- <button id="btn-ticket-resolve" *ngIf="!ticket.resolved">
        <img src="./assets/clear.svg">
        Auflösen
      </button> -->
      <!-- <button id="btn-ticket-restore" *ngIf="ticket.resolved">
        <img src="./assets/clear.svg">
        Wiederherstellen
      </button> -->
      <div class="ticket-item-body-header-column-2">
        <label 
          *ngIf="ticket.type"
          class="lbl-tag"
          [ngClass]="{
            'todo': ticket.type === 1,
            'feature': ticket.type === 2,
            'bug': ticket.type === 3,
            'doku-frage': ticket.type === 4 || ticket.type === 5
          }"
        >{{ getTypeLabel(ticket.type) }}</label>
        <label *ngIf="!ticket.resolved" class="lbl-tag tag-red">Offen</label>
        <label *ngIf="ticket.resolved" class="lbl-tag tag-green">Gelöst</label>
      </div>
    </div>
  </div>
  <img class="ticket-item-arrow" src="./assets/arrowdropdown.svg"/>
</a>
