<div id="notification_container" *ngIf="show">
  <div id="notification_box" [ngClass]="isSuccess ? 'success_box' : 'fail_box'">
    <div class="column_1" [ngClass]="isSuccess ? 'success' : 'fail'">
      <img class="icon" [src]="isSuccess ? '../../../../assets/thumbsUp.svg' : '../../../../assets/thumbsDown.svg'">
    </div>
    <div class="column_2">
      <div class="container_txt">
        <label class="lbl_medium_28 title" [ngClass]="isSuccess ? 'success_text' : 'fail_text'">{{title}}</label>
        <label class="lbl_regular_14 message" [ngClass]="isSuccess ? 'success_text' : 'fail_text'">{{message}}</label>
      </div>
    </div>
  </div>
</div>
